import { join } from 'path';
import SectionBuilder from '../templates/SectionBuilder';
import { returnStaticProps } from '../lib/_helpers';
import { isProductionBuild } from '../helpers/is-env';
import { returnTemplate } from '../lib/_client';
import { PAGE_WP_TYPE } from '../contentTypes/page';
import { readFileWithLog } from '../helpers/fs-helpers';
import { logWithPrefix } from '../helpers/log-helpers';
import { LOCALES } from '../locales';

const logPrefix = 'pages/[...slug]';

const getWPData = ({ locale, slug }) => {
  if (!process.env.VERCEL || process.env.CI === '1') {
    let wpData = readFileWithLog({
      defaultValue: null,
      filePath: join('./cache', PAGE_WP_TYPE, `${slug}__${locale}.json`),
      logPrefix,
      noSuccessLog: true,
    });

    if (!wpData && locale !== LOCALES.EN_US) {
      // See if the US version is available. If yes, we will use that (backward compatible)
      wpData = readFileWithLog({
        defaultValue: null,
        filePath: join(
          './cache',
          PAGE_WP_TYPE,
          `${slug}__${LOCALES.EN_US}.json`
        ),
        logPrefix,
        noSuccessLog: true,
      });
    }

    return wpData;
  }

  return null;
};

export default function Template(props) {
  return returnTemplate(props, SectionBuilder);
}

export async function getStaticPaths() {
  let paths = [];

  if (isProductionBuild()) {
    paths = readFileWithLog({
      defaultValue: [],
      filePath: join('./cache', PAGE_WP_TYPE, '_paths.json'),
      logPrefix,
      noSuccessLog: true,
    });

    logWithPrefix({
      prefix: logPrefix,
      message: `Generated build-time paths: ${paths.length}`,
    });
  }

  return {
    paths: paths.map(({ params: { slug }, locale }) => {
      return {
        locale,
        params: {
          slug,
        },
      };
    }),
    fallback: 'blocking',
  };
}

export async function getStaticProps(props) {
  props.params.slug = props.params.slug.join('/');

  return await returnStaticProps({
    ...props,
    wpData: getWPData({
      locale: props.locale,
      slug: props.params.slug,
    }),
    type: PAGE_WP_TYPE,
  });
}
